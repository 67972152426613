// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Projects {
    background: linear-gradient(var(--wc-text-darkness),#091714, var(--wc-text-darkness));
    width:100%;
    padding: 45px 0;
}

@media (max-width:768px) {
    .Projects {
        padding: 20px 0 0 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Components/Sections/Projects/index.css"],"names":[],"mappings":"AAAA;IACI,qFAAqF;IACrF,UAAU;IACV,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".Projects {\n    background: linear-gradient(var(--wc-text-darkness),#091714, var(--wc-text-darkness));\n    width:100%;\n    padding: 45px 0;\n}\n\n@media (max-width:768px) {\n    .Projects {\n        padding: 20px 0 0 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
