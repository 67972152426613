import { Link } from 'react-router-dom';
import { scroller } from "react-scroll";
import "./index.css";

import * as Icon from 'react-feather'; 

function ScrollToContact() {
    scroller.scrollTo("Contact", {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
    });
};

function ScrollToActivity() {
    scroller.scrollTo("Activity", {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
    });
};

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyLeft">
                <p className="Hello"><hr />Hello Visitant</p>
                <div className="developerBox"><p className="developerText">Back-End Developer</p></div>
                <p className="textHeading">A portfolio,without much frills.</p>
                <p className="textMuted">// Shall we develop the future together?</p>
                <p className="textMutedResponse"><u>Let</u>'s go<p className="barWriting">|</p></p>
                <div className="Buttons">
                <Link onClick={ScrollToContact} to="#">
                    <p className="buttonContact"><Icon.Phone /> <p className="textContact">Contact</p></p>
                </Link>
                <Link onClick={ScrollToActivity} to="#">
                    <p className="buttonActivity"><Icon.Codesandbox /> <p className="textActivity">Activity</p></p>
                </Link>
                </div>
                <div className="experienceBox">
                    <p className="yearText">3+</p>
                    <p className="experienceText">Year<br></br>Experience</p>
                </div>
            </div>

            <div className="bodyRight">

                <div className="projectThreeBox">
                    <div className="Box">
                        <div className="headerBox">
                            <p>Projects Full-Stack Developer</p>
                            <p>+775 Commits</p>
                        </div>
                        <div className="bodyBox">
                            <p>
                                app.get('null', (req, res)<br /><br />
                                // I'm Developer Back-End<br />
                                // 5+ project's with focus in Back-End<br />
                                // Api rest conclused<br />
                                // Others projects for me experience.<br /><br />
                                console.log('More info?')
                            </p>
                            <p className="bodyButton">Maybe, who know?</p>
                        </div>
                    </div>
                </div>

                <div className="projectTwoBox">
                    <div className="Box">
                        <div className="headerBox">
                            <p>Projects Front-End Developer</p>
                            <p>+450 Commits</p>
                        </div>
                        <div className="bodyBox">
                            <p>
                                app.get('hello', (req, res)<br /><br />
                                // I'm Developer Back-End<br />
                                // 5+ project's with focus in Back-End<br />
                                // Api rest conclused<br />
                                // Others projects for me experience.<br /><br />
                                console.log('More info?')
                            </p>
                            <p className="bodyButton">No is my focus</p>
                        </div>
                    </div>
                </div>

                <div className="projectOneBox">
                    <div className="Box">
                        <div className="headerBox">
                            <p>Projects Back-End Developer</p>
                            <p>+1550 Commits</p>
                        </div>
                        <div className="bodyBox">
                            <p>
                                app.post('rest', (req, res)<br /><br />
                                // I'm Developer Back-End<br />
                                // 5+ project's with focus in Back-End<br />
                                // Api rest conclused<br />
                                // Others projects for me experience.<br /><br />
                                console.log('More info?')
                            </p>
                            <p className="bodyButton">See more</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}