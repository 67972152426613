import Body from "./Body";

import "./index.css";

export default function Skills() {

    return (
        <div className="Skills">
            <Body />
        </div>
    );

}