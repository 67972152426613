import { Link } from "react-router-dom";

import "./index.css";
import * as Icon from "react-feather";

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyCenter">
                <p className="Hello"><hr /> Projects <hr /></p>
                <p className="Resume">
                    Some projects created and developed by me.
                </p>
            </div>

            <div className="bodyColumns">

            <div className="bodyLeft">

                <div className="boxProjects">
                    <div className="boxProject boxDark">
                        <p><i>dash-admin</i></p>
                        <p>Dash for platform settings.</p>
                    </div>
                    <div className="boxProject boxPrimary">
                        <p><i>plat-doc</i></p>
                        <p>Platform interactive.</p>
                    </div>
                </div>

                <div className="boxProjects">
                    <div className="boxProject boxDark">
                        <p><i>racefit-web</i></p>
                        <p>Constructed website for academy.</p>
                    </div>
                </div>

                <div className="boxProjects">
                    <div className="boxProject boxPurple">
                        <p><i>ucard-visit</i></p>
                        <p>Cards of visitation online</p>
                    </div>
                </div>

            </div>

            <div className="bodyRight">

                <div className="boxProjects">
                    <div className="boxProject boxPrimary">
                        <p>ecommerce-shop</p>
                        <p>Store maintenance in Shopify.</p>
                    </div>
                </div>

                <div className="boxProjects">
                    <div className="boxProject boxPurple">
                        <p>gft-bsoft-web</p>
                        <p>Integrated transport system, website and tracking.</p>
                    </div>
                    <div className="boxProject boxDark">
                        <p>chatbot-api</p>
                        <p>Integrated messages and leads.</p>
                    </div>
                </div>

                <div className="boxProjects">
                    <div className="boxProject boxPrimary">
                        <p>erp-shopify-integration</p>
                        <p>Integration the ERP x E-Commerce</p>
                    </div>
                </div>

            </div>

            </div>

            <div className="bodyFooter">
                <p className="textGithub"><Icon.GitHub /> GitHub Private <a href="https://github.com/webcarlos19" target="_blank">@webcarlos19</a></p>
                <p className="textComment">
                    // Due to restrictions, we do not share projects code or url.
                </p>
                <Icon.ChevronsDown className="mouseScroll" />
            </div>

        </div>
    )
}