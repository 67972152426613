import "./index.css";

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyCenter">
                <p className="Hello"><hr /> Thank You! <hr /></p>
                <p className="Resume">&copy; All rights reserved - 2024</p>
            </div>

        </div>
    )
}