import "./index.css";

import Body from "./Body";

export default function Footer() {
    return (
        <div className="Footer">
            <Body />
        </div>
    );
}