// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
    background-color: #091714;
    color: #eaeaea;
    width:100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Components/Sections/Header/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,UAAU;AACd","sourcesContent":[".Header {\n    background-color: #091714;\n    color: #eaeaea;\n    width:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
