import "./index.css";
import * as Icon from "react-feather";

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyLeft">

                <div className="boxActivitys">
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Tool /> Create Tools</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Activity /> Analytics</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.GitCommit /> API</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Database /> Database</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Cpu /> IT consultant</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.ShoppingCart /> E-commerce</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Search /> Bugs/Fails</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.AlertCircle /> Maintenance</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Lock /> Security</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Users /> Platforms</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.Server /> Servers</p>
                    </div>
                    <div className="boxActivity boxDark">
                        <p className="boxIcon"><Icon.GitMerge /> Integration</p>
                    </div>
                </div>

            </div>

            <div className="bodyRight">

                <p className="Hello">Activity <hr /></p>
                <p className="textHeading">
                    How can I be of assistance?<br />
                    In several things
                </p>
                <p className="Resume">
                    Focused on developing solutions,<br /><br />
                    Here are some activities that i do.
                </p>

            </div>

        </div>
    )
}