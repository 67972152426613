import {
    BrowserRouter,
    Routes,
    Route
  } from 'react-router-dom';
  
  import Home from './Pages/Home';
  import Projects from './Pages/Projects';
  import Contact from './Pages/Contact';
  import Examples from './Pages/Examples';
  
  const RoutesExport = () => (
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/projects" element={<Projects />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/examples" element={<Examples />} />
    <Route
    path="*"
    element={
      <main style={{ padding: "1rem" }}>
        <p>There's nothing here</p>
      </main>
    }
    />
  </Routes>
  </BrowserRouter>
    );
  
  export default RoutesExport;