import Nav from "./Nav";
import Body from "./Body";

import "./index.css";

export default function Header() {

    return (
        <div className="Header">
            <Nav />
            <Body />
        </div>
    );

}