import { Link } from 'react-router-dom';
import "./index.css";

import * as Icon from 'react-feather';

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyLeft">
                <p className="Hello"><hr /> Contact</p>
                <p className="textHeading">Did you like it?<br />I liked too.</p>
                <p className="textDescription">
                    Now, contact me.
                </p>
                <div className="Buttons">
                    <a href="https://wa.link/d27sqc" target="_blank">
                        <p className="Button"><Icon.Phone /> <p className="textButton">Whatsapp</p></p>
                    </a>
                    <a href="https://instagram.com/webcarlos19" target="_blank">
                        <p className="Button"><Icon.Instagram /> <p className="textButton">Instagram</p></p>
                    </a>
                    <a href="https://www.linkedin.com/in/webcarlos19/" target="_blank">
                        <p className="Button"><Icon.Linkedin /> <p className="textButton">Linkedin</p></p>
                    </a>
                    <a href="https://github.com/webcarlos19" target="_blank">
                        <p className="Button"><Icon.GitHub /> <p className="textButton">GitHub</p></p>
                    </a>
                </div>
                <p className="textMotivation">// It's not a test if you know it will work.<br /><i>- Jeff Bezos</i></p>
            </div>

            <div className="bodyRight">

                <div className="Form">
                      {/*<input type="text" name='name' placeholder='Your name'></input>
                        <input type="email" name='email' placeholder='E-mail'></input>
                        <input type="text" name='comments' placeholder='Your comments'></input>
                        <input type="submit" value="Send"></input>*/}
                </div>

            </div>

        </div>
    )
}