import "./index.css";
import { ChevronsDown } from "react-feather";
import beforeAfterCarlos from "../../../../../../Media/Me/beforeafter.png";

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyLeft">
                <p className="Hello"><hr /> Introduce</p>
                <p className="textHeading">Hello!<br />I'am <b>Carlos</b> Eduardo</p>
                <p className="textDescription">
                    I'm a <i>web developer</i>, my focus is <i>Back-End</i>.<br />
                    I started programming in 2010, entering <i>PHP</i>,<br />
                    when trying to create a Habbo pirate, for 1 year,<br />
                    i didn't know how to do, until i learned and<br />
                    then i started programming CMS for Habbo.<br /><br />
                    On a computer that only had <i>2GB Memory Ram</i>,<br />
                    on an old table, in a house on invasion ground.
                </p>
                <p className="textMotivation"><i>// I haven't arrived yet, but I'm not in the same place anymore.</i></p>
                <p className="iconScroll"><ChevronsDown /></p>
            </div>

            <div className="bodyRight">

                <div className="Images">
                    <img src={beforeAfterCarlos} />
                </div>

            </div>

        </div>
    )
}