// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
    background: linear-gradient(#091714,#091714);
    width:100%;
    padding: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Components/Sections/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,UAAU;IACV,eAAe;AACnB","sourcesContent":[".Footer {\n    background: linear-gradient(#091714,#091714);\n    width:100%;\n    padding: 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
