import { scroller } from 'react-scroll';

import './index.css';

function ScrollToContact() {
    scroller.scrollTo("Contact", {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
    });
};

function ScrollToProjects() {
    scroller.scrollTo("Projects", {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
    });
};

export default function Nav() {
    return(
        <div className="Nav">

            <div className="navLeft">
                <h2 className="Title"><b>Carlos</b> Eduardo</h2>
            </div>

            <div className="navRight">
                <a className="navOptions navActive">home</a>
                <a onClick={ScrollToProjects} className="navOptions">projects</a>
                <a onClick={ScrollToContact} className="navOptions">contact</a>
            </div>

        </div>
    );
}