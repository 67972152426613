// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Introduce {
    background: linear-gradient(#091714, var(--wc-text-darkness), var(--wc-text-darkness));
    width:100%;
    padding: 45px 0;
}

@media (max-width: 768px) {
    .Introduce {
        padding: 15px 0 5px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Components/Sections/Introduce/index.css"],"names":[],"mappings":"AAAA;IACI,sFAAsF;IACtF,UAAU;IACV,eAAe;AACnB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".Introduce {\n    background: linear-gradient(#091714, var(--wc-text-darkness), var(--wc-text-darkness));\n    width:100%;\n    padding: 45px 0;\n}\n\n@media (max-width: 768px) {\n    .Introduce {\n        padding: 15px 0 5px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
