import Body from "./Body";

import "./index.css";

export default function Introduce() {

    return (
        <div className="Introduce">
            <Body />
        </div>
    );

}