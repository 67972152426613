import React from "react";

import "./index.css";

import Header from "./Components/Sections/Header";
import Introduce from "./Components/Sections/Introduce";
import Skills from "./Components/Sections/Skills";
import Projects from "./Components/Sections/Projects";
import Activity from "./Components/Sections/Activity";
import Contact from "./Components/Sections/Contact";
import Footer from "./Components/Sections/Footer";

export default function Home() {
  return (
        <div className="Home">
          <Header />
          <Introduce />
          <Skills />
          <Projects />
          <Activity />
          <Contact />
          <Footer />
        </div>
    );
}
