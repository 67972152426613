// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--wc-text-secondary:#eaeaea;--wc-text-primary:#64ffda;--wc-text-purple:#9f64ff;--wc-text-muted:#bcbcbc;--wc-text-dark:#232323;--wc-text-darkness:#151515;}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar { width: 8px; height: 3px;}
::-webkit-scrollbar-track {  background-color: var(--wc-text-dark);}
::-webkit-scrollbar-track-piece { background-color: var(--wc-text-darkness);}
::-webkit-scrollbar-thumb { height: 50px; background-color: var(--wc-text-dark); border-radius: 3px;}
::-webkit-scrollbar-corner { background-color: #646464;}
::-webkit-resizer { background-color: #666;}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  all: unset;
}

a {
  text-decoration: none;
  color: var(--wc-text-primary);
}`, "",{"version":3,"sources":["webpack://./src/Styles/Main.css"],"names":[],"mappings":"AAEA,MAAM,2BAA2B,CAAC,yBAAyB,CAAC,wBAAwB,CAAC,uBAAuB,CAAC,sBAAsB,CAAC,0BAA0B,CAAC;;AAE/J;EACE,SAAS;EACT,UAAU;EAEV,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;IACI,SAAS;IACT,UAAU;IACV,qCAAqC;AACzC;;AAEA;EACE,uBAAuB;AACzB;;AAEA,sBAAsB,UAAU,EAAE,WAAW,CAAC;AAC9C,6BAA6B,qCAAqC,CAAC;AACnE,kCAAkC,yCAAyC,CAAC;AAC5E,4BAA4B,YAAY,EAAE,qCAAqC,EAAE,kBAAkB,CAAC;AACpG,6BAA6B,yBAAyB,CAAC;AACvD,oBAAoB,sBAAsB,CAAC;;AAE3C;IACI;aACS;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');\n\n:root{--wc-text-secondary:#eaeaea;--wc-text-primary:#64ffda;--wc-text-purple:#9f64ff;--wc-text-muted:#bcbcbc;--wc-text-dark:#232323;--wc-text-darkness:#151515;}\n\n* {\n  margin: 0;\n  padding: 0;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  -webkit-appearance: none;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto Mono', monospace;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n::-webkit-scrollbar { width: 8px; height: 3px;}\n::-webkit-scrollbar-track {  background-color: var(--wc-text-dark);}\n::-webkit-scrollbar-track-piece { background-color: var(--wc-text-darkness);}\n::-webkit-scrollbar-thumb { height: 50px; background-color: var(--wc-text-dark); border-radius: 3px;}\n::-webkit-scrollbar-corner { background-color: #646464;}\n::-webkit-resizer { background-color: #666;}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\ninput {\n  all: unset;\n}\n\na {\n  text-decoration: none;\n  color: var(--wc-text-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
