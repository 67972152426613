import "./index.css";
import { ChevronsDown } from "react-feather";

export default function Body() {
    return (
        <div className="Body">

            <div className="bodyLeft">
                <p className="Hello"><hr /> Skills</p>
                <p className="jsonSkills"><br />
                    <i>"name"</i>: <b>"Carlos Eduardo"</b>,<br />
                    <i>"dev"</i>: <b>"Back-End"</b>,<br />
                    <i>"classification"</i>: <b>"Pleno"</b>,<br />
                    <i>"best_skill"</i>: <b>"Resilient"</b>,<br />
                    <i>"experience"</i>: <b>"3+ years"</b>
                    <br />
                </p>
                <p className="textHeading">A little about my skills.</p>
                <p className="Resume">
                    In the programming world, learning is necessary, updating is mandatory, falling behind is not an option.
                </p>
                <ChevronsDown />
            </div>

            <div className="bodyRight">

                <div className="boxSkills">
                    <p className="Nodejs">#nodejs</p>
                    <p>#git</p>
                    <p className="Expressjs">#expressjs</p>
                    <p>#rest-api</p>
                    <p className="Php">#php</p>
                </div>

                <div className="boxSkills">
                    <p>#html5</p>
                    <p className="React">#react</p>
                    <p>#slack</p>
                    <p className="Mysql">#mysql</p>
                    <p>#json</p>
                    <p className="Clickup">#clickup</p>
                </div>

                <div className="boxSkills">
                    <p className="Javascript">#javascript</p>
                    <p>#full-stack</p>
                    <p className="Css3">#css3</p>
                    <p>#bootstrap</p>
                    <p>#apache</p>
                    <p className="Ruby">#ruby</p>
                </div>

                <div className="boxSkills">
                    <p>#uikit</p>
                    <p className="Photoshop">#photoshop</p>
                    <p>#github</p>
                    <p className="Linux">#linux</p>
                    <p>#nginx</p>
                </div>

                <div className="boxSkills">
                    <p className="Npm">#npm</p>
                    <p>#ajax</p>
                    <p className="Windows">#windows-server</p>
                    <p>#jquery</p>
                </div>

            </div>

        </div>
    )
}