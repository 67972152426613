// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact {
    background: linear-gradient(var(--wc-text-darkness),#091714);
    width:100%;
}

@media (max-width:768px) {
    .Contact {
        padding: 20px 0 0 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Components/Sections/Contact/index.css"],"names":[],"mappings":"AAAA;IACI,4DAA4D;IAC5D,UAAU;AACd;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".Contact {\n    background: linear-gradient(var(--wc-text-darkness),#091714);\n    width:100%;\n}\n\n@media (max-width:768px) {\n    .Contact {\n        padding: 20px 0 0 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
