import Body from "./Body";

import "./index.css";

export default function Projects() {
    return (
        <div className="Projects">
            <Body />
        </div>
    )
}